@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/** colors **/

:root {
  --midnight: #122634;
  --light-midnight: #455966;
  --pool: #b2ece7;
  --chartreuse: #d8d239;
  --cabana: #07a0ad;
  --peach: #ff9876;
  --limoncello: #f5f6b4;
  --nectar: #ffd6c9;
  --duvet: #ffffff;
  --linen: #f9f9f9;
  --ink: #000000;
}

html,
body {
  background-color: hsl(var(--linen));
}

/** typography **/

body {
  font-family: 'Poppins', sans-serif;
}

.header-1 {
  font-family: 'Poppins', sans-serif;
  font-size: 60px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: -1.08px;
  color: var(--midnight);
}

.header-2 {
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: -1.5px;
  color: var(--midnight);
}

.header-3 {
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: -0.94px;
  color: var(--midnight);
}

.header-4 {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.14px;
  color: var(--light-midnight);
}

.header-5 {
  font-family: 'Poppins', sans-serif;
  font-size: 13.3px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.41;
  letter-spacing: 1.62px;
  color: var(--light-midnight);
}

/** buttons **/

.btn {
  @apply font-bold text-xl;
}

.btn-outline {
  @apply border-2;
}

.btn-outline.btn-secondary {
  @apply text-primary border-2 border-secondary;
}

.btn-outline.btn-accent {
  @apply text-primary border-2 border-accent;
}

/** forms **/

.checkin-input {
  @apply input input-md input-bordered border-2 border-gray-200;
}

.checkin-input:focus {
  @apply border-cabana;
  outline: none;
}

/** date picker **/

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover{
  @apply bg-cabana;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    @apply bg-pool;
}

.react-datepicker__input-container {
  @apply flex flex-row;
}

/** tables **/

table.checkin-table {
  @apply w-full;
  border-collapse: collapse;
}

table.checkin-table th {
  @apply font-semibold;
}

table.checkin-table td,
th {
  @apply p-2;
  text-align: left;
  vertical-align: middle;
}

/** modals **/

.help-modal-box {
  @apply modal-box max-w-screen-xl p-0 m-0;
  min-height: 95%;
}
