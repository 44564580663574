@import '@adyen/adyen-web/dist/adyen.css';

/*
    Adyen drop-in component CSS overrides
    See: https://docs.adyen.com/online-payments/web-drop-in/customization?tab=dark_4#drop-in-css-classes
*/

.adyen-checkout__label__text {
    @apply label-text;
}
.adyen-checkout__label--focused .adyen-checkout__label__text {
    @apply label-text;
}
.adyen-checkout__input {
    @apply input input-primary;
}
.adyen-checkout__input--focus {
    @apply input input-primary;
}
.adyen-checkout__payment-method__radio {
    @apply radio radio-primary;
}
.adyen-checkout__payment-method__radio--selected {
    @apply radio radio-primary border-primary bg-primary;
}
.adyen-checkout__button {
    @apply btn btn-primary;
}
.adyen-checkout__button--pay {
    @apply btn btn-primary;
}
.adyen-checkout__dropdown__button {
    @apply input input-primary;
}
.adyen-checkout__fieldset__title {
    @apply text-sm uppercase;
}
